import { useEffect, useRef, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import Agenttab from "./AgentTab";
import AgentMobileTab from "./AgentMobileTab";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';
import Header from "../Layout/Header";
export default function AgentVisaApplicationDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const [visaRequest, setVisaRequest] = useState('');
    const [visaApplicants, setVisaApplicants] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }    
    const GetVisaRequestDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETVISAREQUESTBYID + "/" + params.visaRequestId, { ...headerconfig })
            .then((response) => {
                setVisaRequest(response.data.data);
                setVisaApplicants(response.data.data.visaApplicants);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetVisaRequestDetails();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>                
                <div className="dashboard-menus border-top d-none d-lg-block" style={{backgroundColor:'cadetblue'}}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Visa Application Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <AgentMobileTab></AgentMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-ticket me-2" />My Application Details</h4>
                                    </div>
                                    <div className="card">
                            <div className="card-body">
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Visa</b> : {visaRequest.visaName}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Country</b> : {visaRequest.countryName}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Travel Date</b> : {moment(visaRequest.travelDate).format('Do MMM yyyy')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Applicants</b> : {visaRequest.noOfApplicants}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Price</b> : {visaRequest.pricePerApplicant} INR</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Total Price</b> : {visaRequest.totalPrice} INR</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>TransactionNo</b> : {visaRequest.transactionNo}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Payment Gateway Reference</b> : {visaRequest.paymentGatewayNo}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Payment Status</b> : {visaRequest.paymentStatus}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Source</b> : {visaRequest.leadSource}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Visa Status</b> :
                                            {visaRequest.visaRequestStatus === "NEW" ? <span className="badge bg-primary p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span> : visaRequest.visaRequestStatus === "APPROVED" ? <span className="text-bg-success p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span> : <span className="badge bg-danger p-1" style={{ marginLeft: "10px" }}>{visaRequest.visaRequestStatus}</span>}</label>
                                    </div>
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Last Updated</b> : {moment(visaRequest.updatedDate).format('Do MMM yyyy hh:mm a')}</label>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-4">
                                        <label className="form-label"><b>Comments</b> : {visaRequest.comments}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <h3>Visa Applicants</h3>
                            </div>
                            <div className="card-body">
                                {visaApplicants.length > 0 && visaApplicants.map((v, index) =>
                                    <div className="accordion lefticon-accordion custom-accordionwithicon accordion-border-box accordion-success" id="default-accordion-example">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                    Applicant {parseInt(index + 1)} : {v.visaApplicantStatus === "NEW" ? <span className="badge bg-primary p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span> : v.visaApplicantStatus === "APPROVED" ? <span className="badge bg-warning p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span> : <span className="badge bg-danger p-1" style={{ marginLeft: "10px" }}>{v.visaApplicantStatus}</span>}
                                                </button>
                                            </h2>
                                            <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                                                <div className="accordion-body">
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Name</b> : {v.title} {v.firstName} {v.lastName}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Gender</b> : {v.gender}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Date Of Birth</b> : {moment(v.dateOfBirth).format('Do MMM yyyy')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Nationality</b> : {v.nationality}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Mobile</b> : {v.mobile}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Email</b> : {v.email}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport Number</b> : {v.passportNumber}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport Issue Date</b> : {moment(v.passportIssueDate).format('Do MMM yyyy')}</label>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label className="form-label"><b>Passport ExpireDate</b> : {moment(v.passportExpireDate).format('Do MMM yyyy')}</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.profileSrc} width='200px' height={200} className="mb-4"></img></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.passportFrontSrc} width='200px' height={200} className="mb-4"></img></li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li><img src={v.passportBackSrc} width='200px' height={200} className="mb-4"></img></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
                {/* <div style={{ visibility: "hidden" }}>
                    <DownloadFlightVoucher ref={contentRef} flightBookingId={params.flightBookingId}></DownloadFlightVoucher>
                </div> */}
            </div>
        </div>
    )
}