import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import AgentHeader from "../Layout/HeaderHome";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import APIConfig from "../Configuration/APIConfig";
export default function ConfirmEmail() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handleSubmit = () => {
            
    };
    const applicationAPI = () => {
        return {
            post: (newrecord) => axios.post(APIConfig.APIACTIVATEURL + APIConfig.CONFIRMEMAIL, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        applicationAPI()
            .post(formData)
            .then((res) => {
                if (res.data.statusCode === 200) {
                    alert(res.data.response.message);
                    navigate('/');
                }
                else {
                    if (res.data.response.data == "Invalid token.") {
                        alert("Token Expired.");
                    }
                    else {
                        alert(res.data.response.message);
                    }
                }
            });
    };
    useEffect(() => {
        if (searchParams === null) {
            navigate('/')
        }
        else {
            setEmail(searchParams.get("email"))
            var letparams = searchParams.toString();
            var splitparams = letparams.split('=')
            setToken(splitparams[2])
            const formData = new FormData()
            formData.append("email", searchParams.get("email"))
            formData.append("token", splitparams[2])
            addOrEdit(formData);
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <AgentHeader></AgentHeader>
                <div className="clearfix"></div>
                <div className="py-2 gray-simple position-relative">
                    <h4>Confirming Email. Please wait...</h4>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}