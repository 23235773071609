import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function Agenttab(props) {
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
                <ul className="user-Dashboard-menu">
                    <li className="active"><Link to={"/agent-profile"}><i className="fa-regular fa-id-card me-2" />My Profile</Link></li>
                    <li><Link to={"/agent-flight-bookings"}><i className="fa-solid fa-plane me-2" />Flight Bookings</Link></li>
                    <li><Link to={"/agent-hotel-bookings"}><i className="fa-solid fa-building me-2" />Hotel Bookings</Link></li>
                    <li><Link to={"/agent-wallet"}><i className="fa-solid fa-ticket me-2" />Wallet</Link></li>
                    <li><Link to={"/flight-report"}><i className="fa-solid fa-plane-departure me-2" />Flight Report</Link></li>
                    <li><Link to={"/hotel-report"}><i className="fa-solid fa-home me-2" />Hotel Report</Link></li>
                    <li><Link to={"/logout"}><i className="fa-solid fa-power-off me-2" />Sign Out</Link></li>
                </ul>
            </div>
        </div>
    );
}