import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import config from "../Configuration/APIConfig";
import Header from "../Layout/Header";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import * as XLSX from 'xlsx';
import Agenttab from "../Agents/AgentTab";
export default function HotelReport() {
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [supplierTotal, setSupplierTotal] = useState(0);
    const [saleTotal, setSaleTotal] = useState(0);
    const [markupTotal, setMarkupTotal] = useState(0);
    const [gstTotal, setGSTTotal] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetReport = () => {
        setData([]);
        setSaleTotal(0);
        setSupplierTotal(0);
        axios
            .get(config.APIACTIVATEURL + config.GETHOTELREPORT + "?UserId=" + localStorage.getItem('userId') + "&fromDate=" + moment(fromDate).format('YYYY-MM-DD') + "&toDate=" + moment(toDate).format('YYYY-MM-DD') + "", { ...headerconfig })
            .then((response) => {
                setData(response.data.response.data);
                setSupplierTotal(response.data.response.data.reduce((a, v) => a = a + v.basePrice, 0))
                setSaleTotal(response.data.response.data.reduce((a, v) => a = a + v.totalPrice, 0))
                setMarkupTotal(response.data.response.data.reduce((a, v) => a = a + v.convenienceFee, 0))
                setGSTTotal(response.data.response.data.reduce((a, v) => a = a + v.gstAmount, 0))
            });
    };
    const selectToDate = (nextdate) => {
        var todate = new Date();
        if (nextdate === undefined) {
            todate = addDays(fromDate)
        }
        else {
            todate = nextdate;
        }
        if (todate < fromDate) {
            alert("To Date should be greater than are equal to From Date")
            addDays(fromDate);
        }
        else {
            setFromDate(fromDate);
            setToDate(todate);
        }
    };
    const exportToExcel = () => {
        var fileName = "HotelBookings-" + moment(fromDate).format('DDMMYYYY') + "-" + moment(toDate).format('DDMMYYYY');
        // Create a new workbook
        const wb = XLSX.utils.book_new();
        // Convert the JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(data);
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        // Save the workbook as an Excel file
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    const addDays = (cindate) => {
        console.log(cindate)
        console.log(toDate)
        if (cindate === undefined) {
            cindate = new Date();
        }
        const newDate = new Date(cindate.getTime());
        if (newDate > toDate) {
            newDate.setDate(cindate.getDate() + 1);
            setToDate(newDate);
        }
        else {
            setToDate(toDate)
        }
        setFromDate(cindate);
        return newDate;
    };
    useEffect(() => {
        GetReport();
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>
                <div className="dashboard-menus border-top d-none d-lg-block" style={{ backgroundColor: 'cadetblue' }}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Hotel Bookings Report</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div class="row">
                                    <div class="col-lg-2">
                                            <label for="nameInput" class="form-label">FromDate</label>
                                            <div className="position-relative">
                                                <input type="date" autoComplete="false" className="form-control" name="fromDate" value={fromDate} onChange={(e) => setFromDate(e.target.value)} minDate={moment(new Date()).format('dd-mm-yyyy')} />
                                            </div>
                                        </div>
                                        <div class="col-lg-2">
                                            <label class="form-label">ToDate</label>
                                            <div className="position-relative">
                                                <input type="date" autoComplete="false" className="form-control" name="toDate" value={toDate} onChange={(e) => setToDate(e.target.value)} minDate={moment(new Date()).format('dd-mm-yyyy')} />
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                <button onClick={GetReport} type="button" className="btn btn-md btn-success"><i className="ri-search-line align-middle me-1" /> Search</button>

                                                <button onClick={exportToExcel} type="button" className="btn btn-md btn-primary"><i className="ri-arrow-down-line align-middle me-1" /> Download</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card card-height-100">
                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table align-middle" id="customerTable">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Booking No</th>
                                                            <th>Booking Date</th>
                                                            <th>Dates</th>
                                                            <th>Location</th>
                                                            <th>Traveler</th>
                                                            <th>SupplierPrice</th>
                                                            <th>SalePrice</th>
                                                            <th>Markup</th>
                                                            <th>GST</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="list form-check-all">
                                                        {data.length > 0 && data.map((v, index) =>
                                                            <tr key={v.hotelBookingId}>
                                                                <td>{index + 1}</td>
                                                                <td>{v.bookingNo}</td>
                                                                <td>{moment(v.bookingDate).format('Do MMM yyyy')}</td>
                                                                <td>{moment(v.checkin).format('Do MMM yyyy')} - {moment(v.checkout).format('Do MMM yyyy')}</td>
                                                                <td>{v.city}</td>
                                                                <td>{v.travellerName}</td>
                                                                <td>{Math.round(v.basePrice)} INR</td>
                                                                <td>{Math.round(v.totalPrice)} INR</td>
                                                                <td>{Math.round(v.convenienceFee)} INR</td>
                                                                <td>{Math.round(v.gstAmount)} INR</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th></th>
                                                            <th colSpan={4}></th>
                                                            <th>Total : </th>
                                                            <th>{Math.round(supplierTotal)} INR</th>
                                                            <th>{Math.round(saleTotal)} INR</th>
                                                            <th>{Math.round(markupTotal)} INR</th>
                                                            <th>{Math.round(gstTotal)} INR</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div> {/* .card*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}