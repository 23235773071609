import { useEffect, useState } from "react";
import Footer from "../Layout/Footer";
import "react-datepicker/dist/react-datepicker.css";
import APIConfig from "../Configuration/APIConfig";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Agenttab from "./AgentTab";
import AgentMobileTab from "./AgentMobileTab";
import Header from "../Layout/Header";
export default function AgentVisaApplications() {
    const navigate = useNavigate();
    const [visas, setVisas] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetUserVisas = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETVISAREQUESTBYUSERID + "?id=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                setVisas(response.data.data);
            });
    };
    useEffect(() => {
        if (localStorage.getItem('userId') === null) {
            localStorage.clear();
            navigate("/");
        }
        else {
            GetUserVisas();
        }
    }, [])
    return (
        <div id="main-wrapper">
            <div>
                <Header></Header>
                <div className="clearfix"></div>                
                <div className="dashboard-menus border-top d-none d-lg-block" style={{backgroundColor:'cadetblue'}}>
                    <div className="container">
                        <Agenttab></Agenttab>
                    </div>
                </div>
                <div className="py-2 gray-simple position-relative">
                    <div className="container">
                        {/* Search Form */}
                        <div className="row justify-content-center align-items-center">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0">
                                        <li className="breadcrumb-item"><a href="#" className="text-primary">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Visa Applications</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div><section className="gray-simple position-relative">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <button className="btn btn-dark fw-medium full-width d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDashboard" aria-controls="offcanvasDashboard"><i className="fa-solid fa-gauge me-2" />Dashboard
                                    Navigation</button>
                                <AgentMobileTab></AgentMobileTab>
                            </div>
                        </div>
                        <div className="row align-items-start justify-content-between gx-xl-4">
                            <div className="col-xl-12 col-lg-8 col-md-12">
                                {/* Personal Information */}
                                <div className="card">
                                    <div className="card-header">
                                        <h4><i className="fa-solid fa-ticket me-2" />My Applications</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Booking No</th>
                                                        <th>Destination</th>
                                                        <th>Visa</th>
                                                        <th>Travel Date</th>
                                                        <th>Applicants</th>
                                                        <th>Price</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {visas.length > 0 && visas.map((v, index) =>
                                                        <tr key={v.visaRequestId}>
                                                            <td>{v.visaReferenceNo}</td>
                                                            <td>{v.countryName}</td>
                                                            <td>{v.visaName}</td>
                                                            <td>{moment(v.travelDate).format('DD MMM YYYY')}</td>
                                                            <td>{v.noOfApplicants}</td>                                                            
                                                            <td>{Math.round(v.totalPrice)}</td>
                                                            <td>{v.visaRequestStatus}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                        <Link to={"/agent-visa-application/" + v.visaRequestId} className="btn btn-md btn-primary fw-medium mb-0">View</Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </div>
        </div>
    )
}