import HomeBanner from "../Home/HomeBanner";
import HomeContent from "../Home/HomeContent";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useRef } from "react";
import cities from '../Data/cities.json';
export default function SearchHotel() {
    const navigate = useNavigate();
    const [isDomestic, setIsDomestic] = useState('INTERNATIONAL');
    const [roomInfo, setRoomInfo] = useState([
        {
            adults: 1,
            kids: 1,
            kidsAge: [2],
            adultsFields: [{ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }],
            kidsFields: [{ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }]
        }
    ]);
    const handleChildAgeChange = (roomIndex, childIndex, value) => {
        const updatedRoomInfo = [...roomInfo];
        updatedRoomInfo[roomIndex].kidsAge[childIndex] = value;
        setRoomInfo(updatedRoomInfo);
    };
    const addRoom = () => {
        setRoomInfo([
            ...roomInfo,
            {
                adults: 1,
                kids: 1,
                kidsAge: [2],
                adultsFields: [{ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }],
                kidsFields: [{ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" }]
            }
        ]);
        setTotalRooms(parseInt(totalRooms + 1));
        setTotalAdults(parseInt(totalAdults + 1))
        setTotalKids(parseInt(totalKids + 1))
    };
    const deleteRoom = (index) => {
        if (index !== 0) {
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo.splice(index, 1);
            setRoomInfo(updatedRoomInfo);
            setTotalRooms(parseInt(totalRooms - 1));
            setTotalAdults(parseInt(totalAdults - 1))
            setTotalKids(parseInt(totalKids - 1))
        }
    };

    const [travelerDisplay, setTravelerDisplay] = useState(false);
    const [displayFrom, setDisplayFrom] = useState('DUBAI, DUBAI, UNITED ARAB EMIRATES');
    const [cityId, setCityId] = useState('147862');
    const [city, setCity] = useState('DUBAI');
    const [country, setCountry] = useState('UNITED ARAB EMIRATES');
    const [totalGuests, setTotalGuests] = useState(2);
    const [checkInDate, setCheckInDate] = useState(new Date());
    const [checkOutDate, setCheckOutDate] = useState(new Date());
    const [totalAdults, setTotalAdults] = useState(1);
    const [totalKids, setTotalKids] = useState(1);
    const [totalRooms, setTotalRooms] = useState(1);
    const [errors, setErrors] = useState({});
    const [cityResults, setCityResults] = useState([]);
    const inputCRef = useRef(null);
    const handleCitySearch = (e) => {
        const value = e.target.value;
        setDisplayFrom(value);
        if (value.length > 0) {
            const filteredCities = cities
                .filter(city =>
                    city.cityName.toLowerCase().includes(value.toLowerCase()) && city.type !== 'MULTI_CITY_VICINITY'
                )
                .slice(0, 10);
            setCityResults(filteredCities);
        } else {
            setCityResults([]);
        }
    };
    const handleCityClick = (e) => {
        const filteredCities = cities.slice(0, 10);
        setCityResults(filteredCities);
        inputCRef.current.select();
    };
    const handleCitySelect = (data) => {
        var DF = data.fullRegionName;
        if (data.countryName !== "INDIA") {
            setIsDomestic('INTERNATIONAL')
        }
        else {
            setIsDomestic('DOMESTIC')
        }
        setDisplayFrom(DF);
        setCityId(data.id)
        setCity(data.cityName)
        setCountry(data.countryName)
        setCityResults([]);
    }
    const validate = () => {
        let temp = {};
        temp.displayFrom = displayFrom === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSearch = (e) => {
        e.preventDefault();
        if (validate()) {
            const searchQuery = {
                "cityCode": '' + cityId,
                "city": city,
                "displayFrom": displayFrom,
                "checkInDate": moment(checkInDate).format('YYYY-MM-DD'),
                "checkOutDate": moment(checkOutDate).format('YYYY-MM-DD'),
                "nationality": "106",
                "currency": "INR",
                "country": country,
                "rating": [2, 3, 4, 5],
                "hotelRooms": roomInfo,
                "requestedBy": "AGENT",
                "totalGuests": parseInt(totalAdults + totalKids),
                "totalAdults": totalAdults,
                "totalKids": totalKids,
                "totalRooms": totalRooms,
                "userId":localStorage.getItem('userId'),
                "isDomestic": isDomestic
            }
            navigate('/hotelslist/', { state: { searchQuery } });
        }
    }
    const handleDisplayClick = () => {
        setTravelerDisplay(travelerDisplay => !travelerDisplay);
    }
    const handleIncreaseAdults = (e, index, adultvalue) => {
        if (adultvalue < 7) {
            const value = parseInt(adultvalue + 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].adults = value;
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].adultsFields = avalues;
            }
            setTotalAdults(parseInt(totalAdults + 1))
            setTotalGuests(parseInt(totalGuests + 1))
            setRoomInfo(updatedRoomInfo);
        }

    }
    const handleDecreaseAdults = (e, index, adultvalue) => {
        if (adultvalue !== 1) {
            const value = parseInt(adultvalue - 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].adults = value;
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Mr", firstName: "", lastName: "", travelerType: "ADULT", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].adultsFields = avalues;
            }
            setRoomInfo(updatedRoomInfo);
            setTotalAdults(parseInt(totalAdults - 1))
            setTotalGuests(parseInt(totalGuests - 1))
        }
    }
    const handleIncreaseKids = (e, index, kidvalue) => {
        if (kidvalue < 4) {
            const value = parseInt(kidvalue + 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].kids = value;
            updatedRoomInfo[index].kidsAge = new Array(value).fill(1);
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].kidsFields = avalues;
            }
            setRoomInfo(updatedRoomInfo);
            setTotalKids(parseInt(totalKids + 1))
            setTotalGuests(parseInt(totalGuests + 1))
        }
    }
    const handleDecreaseKids = (e, index, kidvalue) => {
        if (kidvalue !== 0) {
            const value = parseInt(kidvalue - 1);
            const updatedRoomInfo = [...roomInfo];
            updatedRoomInfo[index].kids = value;
            updatedRoomInfo[index].kidsAge = new Array(value).fill(1);
            const avalues = [];
            for (var i = 0; i < value; i++) {
                avalues.push({ title: "Miss", firstName: "", lastName: "", travelerType: "CHILD", dateOfBirth: new Date(), panCard: "", passportNo: "", passportNationality: "", passbookIssueDate: "", passbookExpireDate: "" });
                updatedRoomInfo[index].kidsFields = avalues;
            }
            if (value === 0) {
                updatedRoomInfo[index].kidsFields = avalues;
            }
            setRoomInfo(updatedRoomInfo);
            setTotalKids(parseInt(totalKids - 1))
            setTotalGuests(parseInt(totalGuests - 1))
        }
    }
    const handleCheckInDate = (date) => {
        if (date !== null) {
            setCheckInDate(date)
            const newDate = new Date(date.getTime());
            setCheckOutDate(newDate.setDate(newDate.getDate() + 1));
        }
    }
    const handleCheckOutDate = (date) => {
        if (date !== null) {
            setCheckOutDate(date);
            const newDate = new Date(date.getTime());
            if (moment(newDate).format('DD-MM-YYYY') <= moment(checkInDate).format('DD-MM-YYYY')) {
                setCheckOutDate(newDate.setDate(newDate.getDate() + 1));
            }
            else {
                setCheckOutDate(newDate.setDate(newDate.getDate()));
            }

        }
    }
    const addDays = (cdate) => {
        if (cdate === undefined) {
            cdate = new Date();
        }
        const newDate = new Date(cdate.getTime());
        setCheckOutDate(newDate.setDate(cdate.getDate() + 1));
    }
    useEffect(() => {
        addDays(checkInDate);
    }, [])
    return (
        <div id="main-wrapper">
            <Header></Header>
            <div className="image-cover hero-header bg-white" style={{ background: 'url(/assets/img/2151022224.jpg)' }} data-overlay={1}>
                <div className="container" style={{top:"-100px"}}>
                    {/* Search Form */}
                    <div className="row justify-content-between align-items-left g-4">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="searchableForm bg-white rounded-3 py-3 px-4">
                                <div className="searchableCaps">
                                    <div className="search-wrap">
                                        <div className="tab-content pt-4">
                                            <div className="tab-pane show active" id="hotels">
                                                <div className="row gx-lg-2 g-3">
                                                    <div className="col-xl-3">
                                                        <div className="form-group hdd-arrow mb-0">
                                                            <h6 className="text-dark">CITY</h6>
                                                            <div className="position-relative">
                                                                <input className="form-control" type="text" placeholder="Search for an city" value={displayFrom} onChange={handleCitySearch} onClick={handleCityClick} onFocus={handleCityClick} ref={inputCRef} />
                                                                <span className="icon-inside"><i className="fas fa-location" /></span>
                                                            </div>
                                                            {errors.displayFrom === false ? (<div className="validationerror">Please select city </div>) : ('')}
                                                            {cityResults.length > 0 && (
                                                                <ul className="locationListVideo scrollable-list" style={{ minWidth: "350px", maxWidth: "400px" }}>
                                                                    {cityResults.map((city) => (
                                                                        <li className="locationListVideoItem" key={city.id} onClick={() => handleCitySelect(city)}>
                                                                            {city.fullRegionName}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <h6 className="text-dark">Check-In</h6>
                                                        <div className="position-relative">
                                                            <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="checkInDate" selected={checkInDate} onChange={(date) => handleCheckInDate(date)} dateFormat="EEEE, dd MMM yyyy" minDate={new Date()} />
                                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                            {errors.checkInDate === false ? (<div className="validationerror">Please select checkInDate </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <h6 className="text-dark">Check-Out</h6>
                                                        <div className="position-relative">
                                                            <ReactDatePicker className="form-control" wrapperClassName="datePicker" name="checkOutDate" selected={checkOutDate} onChange={(date) => { handleCheckOutDate(date) }} dateFormat="EEEE, dd MMM yyyy" minDate={checkInDate} />
                                                            <span className="icon-inside"><i className="far fa-calendar-alt" /></span>
                                                            {errors.checkOutDate === false ? (<div className="validationerror">Please select checkOutDate </div>) : ('')}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <h6 className="text-dark">ROOMS & GUESTS</h6>
                                                        <div className="form-group mb-0">
                                                            <div className="booking-form__input guests-input mixer-auto" style={{ top: "60px !important" }}>
                                                                <button name="guests-btn" id="guests-input-btn" className={travelerDisplay === true ? "open" : ""} onClick={handleDisplayClick}>{totalRooms + " Rooms, " + totalAdults + " Adults, " + totalKids + " Children"}</button>
                                                                <div className={travelerDisplay === true ? "guests-input__options open" : "guests-input__options"} id="guests-input-options">
                                                                    {roomInfo.map((room, index) => (
                                                                        <div key={index} className="bott-block d-block">
                                                                            <ul className="list-group list-group-borderless" style={{ marginLeft: "-30px", marginRight: "-30px", marginTop: "-30px" }}>
                                                                                <li className="list-group-item d-flex justify-content-between">
                                                                                    <span className="fw-medium mb-0">Room {index + 1}</span>
                                                                                    <span>
                                                                                        {index !== 0 && (
                                                                                            <Link onClick={deleteRoom} style={{ height: "35px", fontSize: "15px" }} className="btn btn-danger">
                                                                                                <i className="fas fa-trash"></i>
                                                                                            </Link>
                                                                                        )}
                                                                                    </span>
                                                                                </li>

                                                                                {/* Flex container for Adults and Kids */}
                                                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                                    <div className="d-flex justify-content-between w-100">
                                                                                        {/* Adults Section */}
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="guests-input__value mx-2">
                                                                                                <span defaultValue={1} readOnly value={room.adults} name="adults">
                                                                                                    <span className="fw-medium mb-0">Adults (12+)</span>
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="mb-0" style={{ fontSize: "15px" }}>
                                                                                                    <span className="guests-input__ctrl minus m-2" id="adults-subs-btn" onClick={e => handleDecreaseAdults(e, index, room.adults)}>
                                                                                                        <i className="fa-solid fa-minus" />
                                                                                                    </span>
                                                                                                    {room.adults}
                                                                                                    <span className="guests-input__ctrl plus m-2" id="adults-add-btn" onClick={e => handleIncreaseAdults(e, index, room.adults)}>
                                                                                                        <i className="fa-solid fa-plus" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* Kids Section */}
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="guests-input__value mx-2">
                                                                                                <span defaultValue={1} readOnly value={room.kids} name="kids">
                                                                                                    <span className="fw-medium mb-0"> Kids</span>
                                                                                                </span>
                                                                                                <br />
                                                                                                <span className="mb-0" style={{ fontSize: "15px" }}>
                                                                                                    <span className="guests-input__ctrl minus m-2" id="kids-subs-btn" onClick={e => handleDecreaseKids(e, index, room.kids)}>
                                                                                                        <i className="fa-solid fa-minus" />
                                                                                                    </span>
                                                                                                    {room.kids}
                                                                                                    <span className="guests-input__ctrl plus m-2" id="kids-add-btn" onClick={e => handleIncreaseKids(e, index, room.kids)}>
                                                                                                        <i className="fa-solid fa-plus" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>

                                                                                {/* Kids Age Section */}
                                                                                <li>
                                                                                    <div className="row gx-lg-2 gx-3">
                                                                                        {room.kidsAge.length > 0 &&
                                                                                            room.kidsAge.map((age, childIndex) => (
                                                                                                <div className="col-xl-3" key={childIndex}>
                                                                                                    <span>Age {childIndex + 1}</span>
                                                                                                    <select
                                                                                                        className="form-select"
                                                                                                        value={age}
                                                                                                        onChange={e => handleChildAgeChange(index, childIndex, parseInt(e.target.value))}
                                                                                                    >
                                                                                                        {[...Array(12)].map((_, i) => (
                                                                                                            <option key={i + 1} value={i + 1}>
                                                                                                                {i + 1}
                                                                                                            </option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    ))}

                                                                    <div className="d-flex flex-wrap gap-2">
                                                                        <Link onClick={addRoom} style={{ height: "35px", fontSize: "15px" }} className="btn btn-primary">Add Room</Link>
                                                                        <Link onClick={handleDisplayClick} style={{ height: "35px", fontSize: "15px" }} className="btn btn-success">Done</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto px-1" style={{ paddingTop: "28px" }}>
                                                        <div className="form-group mb-0">
                                                            <button type="button" className="btn btn-danger full-width fw-medium" onClick={handleSearch}><i className="fa-solid fa-search-arrow ms-2" />SEARCH</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </row> */}
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}